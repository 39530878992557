import * as React from 'react';
import {Link} from 'gatsby';
import Main from '@components/layout/main';
import TextShadow from '@components/elements/text-shadow';
import Meta from '@components/layout/meta';

function Page() {
    return (
        <>
            <Meta
                title='Bündnis #noIAA'
                description='Klima vor Profit - Mobilitätswende jetzt!'
                type='website'
                path='/'
            />
            <Main>
                <h1 className='relative mx-auto mb-1 text-3xl text-center rubik'>
                    <TextShadow text='404: Hier ist nichts' />
                </h1>
                <Link
                    to='/'
                    className='text-xl underline text-noblue crimson font-weight-700'
                >
                    Zurück zur Startseite
                </Link>
            </Main>
        </>
    );
}

export default Page;
